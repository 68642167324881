/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar,Doughnut,Pie  } from "react-chartjs-2";
import "assets/vendor/nucleo/css/nucleo.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardTitle,
  Badge,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { Checkbox } from 'react-input-checkbox';

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.jsx";

import Header from "components/Headers/Header.jsx";
import {Helmet} from "react-helmet";
import Moment from 'moment';
class Dashboard extends React.Component {

  
  state = {
    versionvalue:1,
    systemversionvalue:3,
    versioncheckopen:false,
    activeNav: 1,
    chartExample1Data: "data1",
    Billtoparty:sessionStorage.getItem('Billtoparty'),
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    linkurl:"https://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"http://crm.mewahtrans.com.my/api/img/profile_img/",
    todaydeliver:0,
    todaypending:0,
    totalinpipeline:0,
    todaydeliveryesterday:0,
    todaypendingyesterday:0,
    totalinpipelineyesterday:0,
    percentdeliver:0,
    percentpending:0,
    tabs:1,
    detailpopout:false,
    ref:window.sessionStorage.getItem('Ref'),
    personalschedulelistimport:[],
    personalschedulelistexport:[],
    alljobschedulelistimport:[],
    alljobschedulelistexport:[],
    loadingdashboard:false,
    currentquotationpagenumber:1,
    sixmonthtotalcontainersize:[0,0,0,0,0,0],
    sixmonthtotalimport:[0,0,0,0,0,0],
    sixmonthtotalexport:[0,0,0,0,0,0],
    labelsixmonth:["Jan","Feb","Mar","Apr","May","Jun"],
    datacontainertotalbymonth: {
      labels: ["Jan","Feb","Mar","Apr","May","Jun"],
      datasets: [
        {
          label: "Container",
          data: [0, 0, 0, 0, 0, 0]
        }
      ]
    },
    datacontainertotalbymonthimportexport: {
      labels: ["Jan","Feb","Mar","Apr","May","Jun"],
      datasets: [{
        label: 'Import ',
        yAxisID: 'Import ',
        data: [0, 0, 0, 0, 0,0],
        backgroundColor: '#77b5fe',
      }, {
        label: 'Export ',
        yAxisID: 'Export ',
        backgroundColor: '#000080',
        data: [0, 0, 0, 0, 0,0]
      }]
    },
    datatopshipperlist: {
      labels: ["Shipper1","Shipper2","Shipper3","Shipper4","Shipper5","Shipper6"],
      datasets: [
        {
          backgroundColor: 'rgb(212,175,55)',
          label: "Shipper",
          data: [0, 0, 0, 0, 0, 0]
        }
      ]
    },
    datalistontimekpi:{    
        labels: ["Fail", "OnTime"],
        datasets: [
          {
            backgroundColor: ['rgb(0, 255, 255)','rgb(0, 128, 128)'],
          
            label: "KPI",
            data: [0, 0]
          }
        ]    
    },

    topshipperlist:[
      { quotationnumber:0,
        shippername:"", 
        containercount:0,                 
      },            
    ],
    ontimekpilistfortable:[],
    failontime:0,
    successontime:0,
    tabsimportexportalljob:1,
    tabsimportexportschedule:1,
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
   // this.getversionvalueandvideolink()
   // this.setState({loadingdashboard:true})
   // this.getshipperlist();
   // this.gettodaypendingandalljobscheduleandschedule();   

    

    //check version value here
   
  }

  getversionvalueandvideolink = () =>{
    this.setState({loadingdashboard:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl + 'mode=admin-getversionvalue'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){                     
            
              this.setState({versionvalue:json.user[0].versionvalue})
              this.setState({versionvalueurlvideo:json.user[0].urlvideo})

              console.log("value1:" +json.user[0].versionvalue )
              console.log("urlvideo:" +json.user[0].urlvideo )

              this.setState({loadingdashboard:true})
              if(this.state.systemversionvalue == json.user[0].versionvalue){
                this.setState({
                  versioncheckopen:false
                })
                console.log("versioncheckopen trigger false")
              }else{
                this.setState({
                  versioncheckopen:true
                  
                })
                console.log("versioncheckopen trigger true")
              }

        }        
               
         else{
          this.setState({loadingdashboard:false})
        console.log(json)
      }
    
     })
  }

  

  getshipperlist = () =>{   
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl + 'mode=admin-getquotationshipperlistdashboard'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){        
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              this.setState({topshipperlist:[...this.state.topshipperlist,
                { quotationnumber:json.user[i].Quotationnumber,
                  shippername:json.user[i].Shipper, 
                  containercount:0,                 
                },            
              ]})
             }

             this.gettotalcontainerbymonthontimekpi();

        }        
               
         else{
          this.setState({loadingdashboard:false})
        console.log(json)
      }
    
     })
  }
  nextpaginationfunction = (e) =>{
    if(this.state.failontime/100 <= this.state.currentquotationpagenumber){
 
    }else{
     this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber +1})
    }
  }
 
  previouspaginationfunction = (e) =>{
   if(this.state.currentquotationpagenumber== 1){
     
   }else{
    this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber - 1})
   }
 }
  renderTablePagination() {
   // console.log("this.state.failontime" + this.state.failontime)
   {if( this.state.failontime/100 < 1){
      return( null )
    }else if(this.state.failontime/100 >= 1 && this.state.failontime/100 < 2 ){
     return (
               <nav aria-label="Page navigation example">
               <Pagination
                 className="pagination justify-content-end"
                 listClassName="justify-content-end"
               >
                   <PaginationItem   >
                     <PaginationLink
                       href="#pablo"
                       onClick={(e) => this.previouspaginationfunction()}
                       tabIndex="-1"
                     >
                       <i className="fa fa-angle-left" />
                       <span className="sr-only">Previous</span>
                     </PaginationLink>
                   </PaginationItem>
                   <PaginationItem className={this.state.currentquotationpagenumber == 1 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:1})}>
                       1
                     </PaginationLink>
                   </PaginationItem>               
                         <PaginationItem className={this.state.currentquotationpagenumber == 2 ? "active" : ""}>
                          <PaginationLink href="#pablo" onClick={(e) => this.setState({currentquotationpagenumber:2})}>
                            2
                          </PaginationLink>
                        </PaginationItem>      
               <PaginationItem>
                       <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                         <i className="fa fa-angle-right" />
                         <span className="sr-only">Next</span>
                       </PaginationLink>
                     </PaginationItem>
                   </Pagination>
                 </nav>                                    
             )
    }else if(this.state.failontime/100 >= 2 && this.state.failontime/100 < 3 ){
     return (
               <nav aria-label="Page navigation example">
               <Pagination
                 className="pagination justify-content-end"
                 listClassName="justify-content-end"
               >
                   <PaginationItem className="disabled">
                     <PaginationLink
                       href="#pablo"
                       onClick={(e) => this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber -1})}
                       tabIndex="-1"
                     >
                       <i className="fa fa-angle-left" />
                       <span className="sr-only">Previous</span>
                     </PaginationLink>
                   </PaginationItem>
                   <PaginationItem className={this.state.currentquotationpagenumber == 1 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:1})}>
                       1
                     </PaginationLink>
                   </PaginationItem>               
                   <PaginationItem className={this.state.currentquotationpagenumber == 2 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:2})}>
                            2
                          </PaginationLink>
                        </PaginationItem>    
                        <PaginationItem className={this.state.currentquotationpagenumber == 3? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:3})}>
                            3
                          </PaginationLink>
                        </PaginationItem>      
               <PaginationItem>
                       <PaginationLink href="#pablo" onClick={(e) => this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber +1})}>
                         <i className="fa fa-angle-right" />
                         <span className="sr-only">Next</span>
                       </PaginationLink>
                     </PaginationItem>
                   </Pagination>
                 </nav>                                    
             )
    }else if(this.state.failontime/100 >= 3 && this.state.failontime/100 < 4 ){
     return (
               <nav aria-label="Page navigation example">
               <Pagination
                 className="pagination justify-content-end"
                 listClassName="justify-content-end"
               >
                   <PaginationItem className="disabled">
                     <PaginationLink
                       href="#pablo"
                       onClick={(e) => this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber -1})}
                       tabIndex="-1"
                     >
                       <i className="fa fa-angle-left" />
                       <span className="sr-only">Previous</span>
                     </PaginationLink>
                   </PaginationItem>
                   <PaginationItem className={this.state.currentquotationpagenumber == 1 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:1})}>
                       1
                     </PaginationLink>
                   </PaginationItem>               
                   <PaginationItem className={this.state.currentquotationpagenumber == 2 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:2})}>
                            2
                          </PaginationLink>
                        </PaginationItem>    
                        <PaginationItem className={this.state.currentquotationpagenumber == 3 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:3})}>
                            3
                          </PaginationLink>
                        </PaginationItem>  
                        <PaginationItem className={this.state.currentquotationpagenumber == 4 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:4})}>
                            4
                          </PaginationLink>
                        </PaginationItem>          
               <PaginationItem>
                       <PaginationLink href="#pablo" onClick={(e) => this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber +1})}>
                         <i className="fa fa-angle-right" />
                         <span className="sr-only">Next</span>
                       </PaginationLink>
                     </PaginationItem>
                   </Pagination>
                 </nav>                                    
             )
    }else if(this.state.failontime/100 >= 4 && this.state.failontime/100 < 5 ){
     return (
               <nav aria-label="Page navigation example">
               <Pagination
                 className="pagination justify-content-end"
                 listClassName="justify-content-end"
               >
                   <PaginationItem className="disabled">
                     <PaginationLink
                       href="#pablo"
                       onClick={e => this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber -1})}
                       tabIndex="-1"
                     >
                       <i className="fa fa-angle-left" />
                       <span className="sr-only">Previous</span>
                     </PaginationLink>
                   </PaginationItem>
                   <PaginationItem className={this.state.currentquotationpagenumber == 1 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:1})}>
                       1
                     </PaginationLink>
                   </PaginationItem>               
                   <PaginationItem className={this.state.currentquotationpagenumber == 2 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:2})}>
                            2
                          </PaginationLink>
                        </PaginationItem>    
                        <PaginationItem className={this.state.currentquotationpagenumber == 3 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:3})}>
                            3
                          </PaginationLink>
                        </PaginationItem>  
                        <PaginationItem className={this.state.currentquotationpagenumber == 4 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:4})}>
                            4
                          </PaginationLink>
                        </PaginationItem>   
                        <PaginationItem className={this.state.currentquotationpagenumber == 5 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:5})}>
                            5
                          </PaginationLink>
                        </PaginationItem>           
               <PaginationItem>
                       <PaginationLink href="#pablo" onClick={e => this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber +1})}>
                         <i className="fa fa-angle-right" />
                         <span className="sr-only">Next</span>
                       </PaginationLink>
                     </PaginationItem>
                   </Pagination>
                 </nav>                                    
             )
    } else if(this.state.failontime/100 >= 5 && this.state.failontime/100 <10 ){
     return (
               <nav aria-label="Page navigation example">
               <Pagination
                 className="pagination justify-content-end"
                 listClassName="justify-content-end"
               >
                   <PaginationItem className="disabled">
                     <PaginationLink
                       href="#pablo"
                       onClick={e => this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber -1})}
                       tabIndex="-1"
                     >
                       <i className="fa fa-angle-left" />
                       <span className="sr-only">Previous</span>
                     </PaginationLink>
                   </PaginationItem>
                   <PaginationItem className={this.state.currentquotationpagenumber == 1 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:1})}>
                       1
                     </PaginationLink>
                   </PaginationItem>               
                   <PaginationItem className={this.state.currentquotationpagenumber == 2 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:2})}>
                            2
                          </PaginationLink>
                        </PaginationItem>    
                        <PaginationItem className={this.state.currentquotationpagenumber == 3 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:3})}>
                            3
                          </PaginationLink>
                        </PaginationItem>  
                        <PaginationItem className={this.state.currentquotationpagenumber == 4 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:4})}>
                            4
                          </PaginationLink>
                        </PaginationItem>   
                        <PaginationItem className={this.state.currentquotationpagenumber == 5 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:5})}>
                            5
                          </PaginationLink>
                        </PaginationItem>  
                        <PaginationItem className={this.state.currentquotationpagenumber == 6 ? "active" : ""}>
                     <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:6})}>
                            ...
                          </PaginationLink>
                        </PaginationItem>           
               <PaginationItem>
                       <PaginationLink href="#pablo" onClick={(e) => this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber +1})}>
                         <i className="fa fa-angle-right" />
                         <span className="sr-only">Next</span>
                       </PaginationLink>
                     </PaginationItem>
                   </Pagination>
                 </nav>                                    
             )
    } 
    }
}
  gettotalcontainerbymonthontimekpi = () =>{

    
    var urlencoded = new URLSearchParams();
    var timestampnow = new Date().getTime()/1000;
    //last 6 month
    var timestamp6monthbefore = Moment().subtract(6,"months")
    var datestart = new Date(timestamp6monthbefore).getDate()
    var twodigitdate = ("0" + datestart).slice(-2);
    var monthstart = new Date(timestamp6monthbefore).getMonth() +1
    var twodigitmonth = ("0" + monthstart).slice(-2);
    var yearstart = new Date(timestamp6monthbefore).getFullYear()
    var totaldatestart = yearstart + "-" + twodigitmonth + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass = new Date(totaldatestart).getTime()
    var starttimetopassnotmillilast6month = starttimetopass/1000

    //last 5 month
    var timestamp5monthbefore5 = Moment().subtract(5,"months")
    var datestart5 = new Date(timestamp5monthbefore5).getDate()
    var twodigitdate = ("0" + datestart5).slice(-2);
    var monthstart5 = new Date(timestamp5monthbefore5).getMonth() +1
    var twodigitmonth5 = ("0" + monthstart5).slice(-2);
    var yearstart5 = new Date(timestamp5monthbefore5).getFullYear()
    var totaldatestart5 = yearstart5 + "-" + twodigitmonth5 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass5 = new Date(totaldatestart5).getTime()
    var starttimetopassnotmillilast5month = starttimetopass5/1000

    //last 4 month
    var timestamp5monthbefore4 = Moment().subtract(4,"months")
    var datestart4 = new Date(timestamp5monthbefore4).getDate()
    var twodigitdate4 = ("0" + datestart4).slice(-2);
    var monthstart4 = new Date(timestamp5monthbefore4).getMonth() +1
    var twodigitmonth4 = ("0" + monthstart4).slice(-2);
    var yearstart4 = new Date(timestamp5monthbefore4).getFullYear()
    var totaldatestart4 = yearstart4 + "-" + twodigitmonth4 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass4 = new Date(totaldatestart4).getTime()
    var starttimetopassnotmillilast4month = starttimetopass4/1000


    //last 3 month
    var timestamp5monthbefore3 = Moment().subtract(3,"months")
    var datestart3 = new Date(timestamp5monthbefore3).getDate()
    var twodigitdate3 = ("0" + datestart3).slice(-2);
    var monthstart3 = new Date(timestamp5monthbefore3).getMonth() +1
    var twodigitmonth3 = ("0" + monthstart3).slice(-2);
    var yearstart3 = new Date(timestamp5monthbefore3).getFullYear()
    var totaldatestart3 = yearstart3 + "-" + twodigitmonth3 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass3 = new Date(totaldatestart3).getTime()
    var starttimetopassnotmillilast3month = starttimetopass3/1000


    //last 2 month
    var timestamp5monthbefore2 = Moment().subtract(2,"months")
    var datestart2 = new Date(timestamp5monthbefore2).getDate()
    var twodigitdate2 = ("0" + datestart5).slice(-2);
    var monthstart2 = new Date(timestamp5monthbefore2).getMonth() +1
    var twodigitmonth2 = ("0" + monthstart2).slice(-2);
    var yearstart2 = new Date(timestamp5monthbefore2).getFullYear()
    var totaldatestart2 = yearstart2 + "-" + twodigitmonth2 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass2 = new Date(totaldatestart2).getTime()
    var starttimetopassnotmillilast2month = starttimetopass2/1000


    //last 1 month
    var timestamp1monthbefore = Moment().subtract(1,"months")
    var datestart1 = new Date(timestamp1monthbefore).getDate()
    var twodigitdate1 = ("0" + datestart1).slice(-2);
    var monthstart1 = new Date(timestamp1monthbefore).getMonth() +1
    var twodigitmonth1 = ("0" + monthstart1).slice(-2);
    var yearstart1 = new Date(timestamp1monthbefore).getFullYear()
    var totaldatestart1 = yearstart1 + "-" + twodigitmonth1 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass1 = new Date(totaldatestart1).getTime()
    var starttimetopassnotmillilast1month = starttimetopass1/1000

    //last this month head
    var timestamp1monthbefore0 = Moment().subtract(0,"months")
    var datestart0 = new Date(timestamp1monthbefore0).getDate()
    var twodigitdate0 = ("0" + datestart0).slice(-2);
    var monthstart0 = new Date(timestamp1monthbefore0).getMonth() +1
    var twodigitmonth0 = ("0" + monthstart0).slice(-2);
    var yearstart0 = new Date(timestamp1monthbefore0).getFullYear()
    var totaldatestart0 = yearstart0 + "-" + twodigitmonth0 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass0 = new Date(totaldatestart0).getTime()
    var starttimetopassnotmillilast1month0 = starttimetopass0/1000
    if( monthstart0 == 1){
      this.state.labelsixmonth[5] = "Jan"
      this.state.labelsixmonth[4] = "Dec"
      this.state.labelsixmonth[3] = "Nov"
      this.state.labelsixmonth[2] = "Oct"
      this.state.labelsixmonth[1] = "Sep"
      this.state.labelsixmonth[0] = "Aug"
    }else if( monthstart0 == 2){
      this.state.labelsixmonth[5] = "Feb"
      this.state.labelsixmonth[4] = "Jan"
      this.state.labelsixmonth[3] = "Dec"
      this.state.labelsixmonth[2] = "Nov"
      this.state.labelsixmonth[1] = "Oct"
      this.state.labelsixmonth[0] = "Sep"
    }else if( monthstart0 == 3){
      this.state.labelsixmonth[5] = "Mar"
      this.state.labelsixmonth[4] = "Feb"
      this.state.labelsixmonth[3] = "Jan"
      this.state.labelsixmonth[2] = "Dec"
      this.state.labelsixmonth[1] = "Nov"
      this.state.labelsixmonth[0] = "Oct"
    }else if( monthstart0 == 4){
      this.state.labelsixmonth[5] = "Apr"
      this.state.labelsixmonth[4] = "Mar"
      this.state.labelsixmonth[3] = "Feb"
      this.state.labelsixmonth[2] = "Jan"
      this.state.labelsixmonth[1] = "Dec"
      this.state.labelsixmonth[0] = "Nov"
    }else if( monthstart0 == 5){
      this.state.labelsixmonth[5] = "May"
      this.state.labelsixmonth[4] = "Apr"
      this.state.labelsixmonth[3] = "Mar"
      this.state.labelsixmonth[2] = "Feb"
      this.state.labelsixmonth[1] = "Jan"
      this.state.labelsixmonth[0] = "Dec"
    }else if( monthstart0 == 6){
      this.state.labelsixmonth[5] = "Jun"
      this.state.labelsixmonth[4] = "May"
      this.state.labelsixmonth[3] = "Apr"
      this.state.labelsixmonth[2] = "Mar"
      this.state.labelsixmonth[1] = "Feb"
      this.state.labelsixmonth[0] = "Jan"
    }else if( monthstart0 == 7){
      this.state.labelsixmonth[5] = "Jul"
      this.state.labelsixmonth[4] = "Jun"
      this.state.labelsixmonth[3] = "May"
      this.state.labelsixmonth[2] = "Apr"
      this.state.labelsixmonth[1] = "Mar"
      this.state.labelsixmonth[0] = "Feb"
    }else if( monthstart0 == 8){
      this.state.labelsixmonth[5] = "Aug"
      this.state.labelsixmonth[4] = "Jul"
      this.state.labelsixmonth[3] = "Jun"
      this.state.labelsixmonth[2] = "May"
      this.state.labelsixmonth[1] = "Apr"
      this.state.labelsixmonth[0] = "Mar"
    }else if( monthstart0 == 9){
      this.state.labelsixmonth[5] = "Sep"
      this.state.labelsixmonth[4] = "Aug"
      this.state.labelsixmonth[3] = "Jul"
      this.state.labelsixmonth[2] = "Jun"
      this.state.labelsixmonth[1] = "May"
      this.state.labelsixmonth[0] = "Apr"
    }else if( monthstart0 == 10){
      this.state.labelsixmonth[5] = "Oct"
      this.state.labelsixmonth[4] = "Sep"
      this.state.labelsixmonth[3] = "Aug"
      this.state.labelsixmonth[2] = "Jul"
      this.state.labelsixmonth[1] = "Jun"
      this.state.labelsixmonth[0] = "May"
    }else if( monthstart0 == 11){
      this.state.labelsixmonth[5] = "Nov"
      this.state.labelsixmonth[4] = "Oct"
      this.state.labelsixmonth[3] = "Sep"
      this.state.labelsixmonth[2] = "Aug"
      this.state.labelsixmonth[1] = "Jul"
      this.state.labelsixmonth[0] = "Jun"
    }else if( monthstart0 == 12){
      this.state.labelsixmonth[5] = "Dec"
      this.state.labelsixmonth[4] = "Nov"
      this.state.labelsixmonth[3] = "Oct"
      this.state.labelsixmonth[2] = "Sep"
      this.state.labelsixmonth[1] = "Aug"
      this.state.labelsixmonth[0] = "Jul"
    }
    


     //next month head
     var timestamp1monthbeforeadd1 = Moment().add(1,"months")
     var datestartadd1 = new Date(timestamp1monthbeforeadd1).getDate()
     var twodigitdateadd1 = ("0" + datestartadd1).slice(-2);
     var monthstartadd1 = new Date(timestamp1monthbeforeadd1).getMonth() +1
     var twodigitmonthadd1 = ("0" + monthstartadd1).slice(-2);
     var yearstartadd1 = new Date(timestamp1monthbeforeadd1).getFullYear()
     var totaldatestartadd1 = yearstartadd1 + "-" + twodigitmonthadd1 + "-" + "01" + "T00:00:00+00:00"
     var starttimetopassadd1 = new Date(totaldatestartadd1).getTime()
     var starttimetopassnotmillilast1monthadd1 = starttimetopassadd1/1000
   

    console.log("starttimetopassnotmillilast1month0: " + starttimetopassnotmillilast1month0)
    console.log("starttimetopassnotmillilast1month: " + starttimetopassnotmillilast1month)
    console.log("starttimetopassnotmillilast2month: " + starttimetopassnotmillilast2month)
    console.log("starttimetopassnotmillilast3month: " + starttimetopassnotmillilast3month)
    console.log("starttimetopassnotmillilast4month: " + starttimetopassnotmillilast4month)
    console.log("starttimetopassnotmillilast5month: " + starttimetopassnotmillilast5month)
    console.log("starttimetopassnotmillilast6month: " + starttimetopassnotmillilast6month)
    console.log("starttimetopassnotmillilast1monthadd1: " + starttimetopassnotmillilast1monthadd1)


    urlencoded.append("billtoparty",this.state.Billtoparty);
    urlencoded.append("starttimetopassnotmilli",starttimetopassnotmillilast6month);
    urlencoded.append("starttimetopassnotmillilast1monthadd1",starttimetopassnotmillilast1monthadd1);
    let link = this.state.linkurl +'mode=admin-gettotalcontainerbymonth'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
       
      if(json.success == 1){
        this.setState({failontime:0})
        this.setState({successontime:0})
        var lengthemployee = Object.keys(json.user).length;
        for(let i=0;i<lengthemployee;i++){        
         
          /////Total Container By Month, IMPORT EXPORT-----------------------------------------------------------------------------------------
          //LAST 5 MONTH
          if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast5month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast4month){
           // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
            if(json.user[i].importexport == "Export"){
              this.state.sixmonthtotalexport[0] = this.state.sixmonthtotalexport[0] + 1
            }else if(json.user[i].importexport == "Import"){
              this.state.sixmonthtotalimport[0] = this.state.sixmonthtotalimport[0] + 1
            }
            this.state.sixmonthtotalcontainersize[0] = this.state.sixmonthtotalcontainersize[0] + 1
          }

          //LAST 4 MONTH
          if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast4month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast3month){
          //  console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
            if(json.user[i].importexport == "Export"){
              this.state.sixmonthtotalexport[1] = this.state.sixmonthtotalexport[1] + 1
            }else if(json.user[i].importexport == "Import"){
              this.state.sixmonthtotalimport[1] = this.state.sixmonthtotalimport[1] + 1
            }
            this.state.sixmonthtotalcontainersize[1] = this.state.sixmonthtotalcontainersize[1] + 1
          }

           //LAST 3 MONTH
           if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast3month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast2month){
           // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
            if(json.user[i].importexport == "Export"){
              this.state.sixmonthtotalexport[2] = this.state.sixmonthtotalexport[2] + 1
            }else if(json.user[i].importexport == "Import"){
              this.state.sixmonthtotalimport[2] = this.state.sixmonthtotalimport[2] + 1
            } 
           this.state.sixmonthtotalcontainersize[2] = this.state.sixmonthtotalcontainersize[2] + 1
          }

             //LAST 2 MONTH
             if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast2month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast1month){
             // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
              if(json.user[i].importexport == "Export"){
                this.state.sixmonthtotalexport[3] = this.state.sixmonthtotalexport[3] + 1
              }else if(json.user[i].importexport == "Import"){
                this.state.sixmonthtotalimport[3] = this.state.sixmonthtotalimport[3] + 1
              }  
             this.state.sixmonthtotalcontainersize[3] = this.state.sixmonthtotalcontainersize[3] + 1
            }

            //LAST 1 MONTH
            if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast1month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast1month0){
             // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
              if(json.user[i].importexport == "Export"){
                this.state.sixmonthtotalexport[4] = this.state.sixmonthtotalexport[4] + 1
              }else if(json.user[i].importexport == "Import"){
                this.state.sixmonthtotalimport[4] = this.state.sixmonthtotalimport[4] + 1
              }   
             this.state.sixmonthtotalcontainersize[4] = this.state.sixmonthtotalcontainersize[4] + 1
            }
          
            //this MONTH
            if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast1month0 ){
                //console.log("thismonth:" + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY"))
                //console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
                if(json.user[i].importexport == "Export"){
                  this.state.sixmonthtotalexport[5] = this.state.sixmonthtotalexport[5] + 1
                }else if(json.user[i].importexport == "Import"){
                  this.state.sixmonthtotalimport[5] = this.state.sixmonthtotalimport[5] + 1
                }   
                this.state.sixmonthtotalcontainersize[5] = this.state.sixmonthtotalcontainersize[5] + 1
            }


            /////top Shipper vs container---------------------------------------------------------------------------
            var lengthoftopshipperlist = Object.keys(this.state.topshipperlist).length;
            for(let j = 0;j< lengthoftopshipperlist ; j ++){
              if(json.user[i].Shipper == this.state.topshipperlist[j].shippername){
                this.state.topshipperlist[j].containercount = this.state.topshipperlist[j].containercount + 1 
                //console.log("topshipplerlistcount: " + this.state.topshipperlist[j].containercount)
            }
          }
         
             /////on time kpi-----------------------------------------------------------
             if(json.user[i].Requestdate-28800 == 946684800-28800 || json.user[i].Activitydatetruckin-28800 == 946684800-28800 ){
             }else{
              if(json.user[i].Requestdate-28800 > json.user[i].Activitydatetruckin-28800 || Moment.unix(json.user[i].Requestdate-28800).format("DD/MM/YYYY") == Moment.unix(json.user[i].Activitydatetruckin-28800).format("DD/MM/YYYY")){
                this.state.successontime = this.state.successontime + 1
              }else{
                this.state.failontime = this.state.failontime + 1
                this.setState({ontimekpilistfortable:[...this.state.ontimekpilistfortable,
                  { no:this.state.failontime,
                    consignmentno:json.user[i].Consignmentno,
                    shipper:json.user[i].Shipper,
                    bookingno:json.user[i].Bookingno,
                    containerno:json.user[i].Containerno,
                    requestdate:Moment.unix(json.user[i].Requestdate-28800).format("DD/MM/YYYY"),
                    assigndate:Moment.unix(json.user[i].Activitydatetruckin-28800).format("DD/MM/YYYY"),
                  },            
                ]})
              }
             }
            

             

        }

          this.state.topshipperlist.sort((a,b) => (a.containercount < b.containercount) ?1:-1)
          //console.log("shipperlistsort: " + this.state.topshipperlist)
          // for(let j = 0;j< lengthoftopshipperlist ; j ++){
          //   if(this.state.topshipperlist[j].containercount != 0){
          //   console.log("topshippernamesort: " + this.state.topshipperlist[j].shippername)
          //   console.log("topshipplerlistcountsort: " + this.state.topshipperlist[j].containercount)
          //   }
          // }
        this.setState({
          datacontainertotalbymonth: {
            labels:this.state.labelsixmonth,
            datasets: [
              {
                label: "Container",
                data: this.state.sixmonthtotalcontainersize,
              }
            ]
          },
        })

        this.setState({
          datacontainertotalbymonthimportexport: {
            labels: this.state.labelsixmonth,
            datasets: [{
              label: 'Import ',
              yAxisID: 'Import ',
              data: this.state.sixmonthtotalimport,
              backgroundColor: '#77b5fe',
            }, {
              label: 'Export ',
              yAxisID: 'Export ',
              backgroundColor: '#000080',
              data: this.state.sixmonthtotalexport,
            }]
          },
        })
             
        if(this.state.topshipperlist[0].shippername == null){
          this.setState({
            datatopshipperlist: {
              labels: [0,0,0,0,0,0],
              datasets: [
                {
                  backgroundColor: 'rgb(212,175,55)',
                  label: "Shipper",
                  data: [this.state.topshipperlist[0].containercount, this.state.topshipperlist[1].containercount, this.state.topshipperlist[2].containercount, this.state.topshipperlist[3].containercount, this.state.topshipperlist[4].containercount, this.state.topshipperlist[5].containercount]
                }
              ]
            },
          })
        }else{
          this.setState({
            datatopshipperlist: {
              labels: [this.state.topshipperlist[0].shippername.slice(0,8),this.state.topshipperlist[1].shippername.slice(0,8),this.state.topshipperlist[2].shippername.slice(0,8),this.state.topshipperlist[3].shippername.slice(0,8),this.state.topshipperlist[4].shippername.slice(0,8),this.state.topshipperlist[5].shippername.slice(0,8)],
              datasets: [
                {
                  backgroundColor: 'rgb(212,175,55)',
                  label: "Shipper",
                  data: [this.state.topshipperlist[0].containercount, this.state.topshipperlist[1].containercount, this.state.topshipperlist[2].containercount, this.state.topshipperlist[3].containercount, this.state.topshipperlist[4].containercount, this.state.topshipperlist[5].containercount]
                }
              ]
            },
          })
        }
          

            this.setState({
              datalistontimekpi:{    
                labels: ["Fail", "OnTime"],
                datasets: [
                  {
                    backgroundColor: ['rgb(0, 255, 255)','rgb(0, 128, 128)'],
                  
                    label: "KPI",
                    data: [this.state.failontime, this.state.successontime]
                  }
                ]    
            },
            })



      }
      else{
          //this.setState({loading:false}) 
          

          this.setState({loadingdashboard:false})
        console.log(json)
      }
    
    })
  
  }

  renderpersonalschedulelist() {
      

    if(this.state.tabsimportexportschedule == 1){
      return this.state.personalschedulelistimport.map((data, index) => {
        const {requestdatetime,containerno,consignmentno,no,shippername,bookingno,estimatetime,status,statuscolor,} = data //destructuring
        var rotlength = Object.keys(this.state.personalschedulelistimport).length;
 
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shippername}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdatetime}</td>
           <td>{estimatetime}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className={statuscolor} />                        
           
           {status}
           </Badge></td>
         </tr>       
       </tbody>
        
    
        )
     })
    }else{
      return this.state.personalschedulelistexport.map((data, index) => {
        const {requestdatetime,containerno,consignmentno,no,shippername,bookingno,estimatetime,status,statuscolor,} = data //destructuring
        var rotlength = Object.keys(this.state.personalschedulelistexport).length;
 
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shippername}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdatetime}</td>
           <td>{estimatetime}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className={statuscolor} />                        
           
           {status}
           </Badge></td>
         </tr>       
       </tbody>
        
    
        )
     })
    }
    
  }

  renderpersonalschedulelistphone() {
      

    if(this.state.tabsimportexportalljob == 1){
      return this.state.personalschedulelistimport.map((data, index) => {
        const {requestdatetime,containerno,consignmentno,no,shippername,bookingno,estimatetime,status,statuscolor,} = data //destructuring
        var rotlength = Object.keys(this.state.personalschedulelistimport).length;
        return (
           <div className="mt-1 cardviewbox">
             <div className="horizontaldirection">
               <h4 className="ml-4 mt-10 mb-0 ">{consignmentno}</h4>
               <Badge color="" className="badge-dot mr-4 ml-4  mb-10 ml-auto">          
                 <i className={statuscolor} /> 
                 {status}
                 </Badge>
             </div>
              
               <p className="ml-4 mr-10 phoneviewcontent" >{shippername}</p>
               <p className="ml-4 phoneviewcontent ">{bookingno}</p>
               <p className="ml-4 phoneviewcontent ">{containerno}</p>
               <p className="ml-4  phoneviewcontent">{requestdatetime}</p>
               <p className="ml-4  phoneviewcontent">{estimatetime}</p>
              
           </div>       
    
        )
     })
    }else{
      return this.state.personalschedulelistexport.map((data, index) => {
        const {requestdatetime,containerno,consignmentno,no,shippername,bookingno,estimatetime,status,statuscolor,} = data //destructuring
        var rotlength = Object.keys(this.state.personalschedulelistexport).length;
        return (
           <div className="mt-1 cardviewbox">
             <div className="horizontaldirection">
               <h4 className="ml-4 mt-10 mb-0 ">{consignmentno}</h4>
               <Badge color="" className="badge-dot mr-4 ml-4  mb-10 ml-auto">          
                 <i className={statuscolor} /> 
                 {status}
                 </Badge>
             </div>
              
               <p className="ml-4 mr-10 phoneviewcontent" >{shippername}</p>
               <p className="ml-4 phoneviewcontent ">{bookingno}</p>
               <p className="ml-4 phoneviewcontent ">{containerno}</p>
               <p className="ml-4  phoneviewcontent">{requestdatetime}</p>
               <p className="ml-4  phoneviewcontent">{estimatetime}</p>
              
           </div>       
    
        )
     })
    }
  }

  renderalljobschedulelistphone() {
      

  if(this.state.tabsimportexportalljob == "Import"){
    return this.state.alljobschedulelistimport.map((data, index) => {
      const {requestdatetime,containerno,consignmentno,no,shippername,bookingno,estimatetime,status,statuscolor,} = data //destructuring
      var rotlength = Object.keys(this.state.alljobschedulelistimport).length;
      return (
       <div className="mt-1 cardviewbox">
           <div className="horizontaldirection">
             <h4 className="ml-4 mt-10 mb-0 ">{consignmentno}</h4>
             <Badge color="" className="badge-dot mr-4 ml-4  mb-10 ml-auto">          
               <i className={statuscolor} /> 
               {status}
               </Badge>
           </div>
            
             <p className="ml-4 mr-10 phoneviewcontent" >{shippername}</p>
             <p className="ml-4 phoneviewcontent ">{bookingno}</p>
             <p className="ml-4 phoneviewcontent ">{containerno}</p>
             <p className="ml-4  phoneviewcontent">{requestdatetime}</p>
             <p className="ml-4  phoneviewcontent">{estimatetime}</p>
            
         </div>     
      
  
      )
   })
  }else{
    return this.state.alljobschedulelistexport.map((data, index) => {
      const {requestdatetime,containerno,consignmentno,no,shippername,bookingno,estimatetime,status,statuscolor,} = data //destructuring
      var rotlength = Object.keys(this.state.alljobschedulelistexport).length;
      return (
       <div className="mt-1 cardviewbox">
           <div className="horizontaldirection">
             <h4 className="ml-4 mt-10 mb-0 ">{consignmentno}</h4>
             <Badge color="" className="badge-dot mr-4 ml-4  mb-10 ml-auto">          
               <i className={statuscolor} /> 
               {status}
               </Badge>
           </div>
            
             <p className="ml-4 mr-10 phoneviewcontent" >{shippername}</p>
             <p className="ml-4 phoneviewcontent ">{bookingno}</p>
             <p className="ml-4 phoneviewcontent ">{containerno}</p>
             <p className="ml-4  phoneviewcontent">{requestdatetime}</p>
             <p className="ml-4  phoneviewcontent">{estimatetime}</p>
            
         </div>     
      
  
      )
   })
  }
  }

  renderontimekpimodallist() {
      
    if(this.state.currentquotationpagenumber ==1){
      return this.state.ontimekpilistfortable.slice(0,50).map((data, index) => {
        const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
        var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shipper}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdate}</td>
           <td>{assigndate}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className="bg-warning" />                        
           
           Fail
           </Badge></td>
         </tr>  
       </tbody>
        )
     })         
    }else if(this.state.currentquotationpagenumber ==2){
      return this.state.ontimekpilistfortable.slice(50,100).map((data, index) => {
        const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
        var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shipper}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdate}</td>
           <td>{assigndate}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className="bg-warning" />                        
           
           Fail
           </Badge></td>
         </tr>  
       </tbody>
        )
     })     
    }else if(this.state.currentquotationpagenumber ==3){
      return this.state.ontimekpilistfortable.slice(100,150).map((data, index) => {
        const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
        var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shipper}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdate}</td>
           <td>{assigndate}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className="bg-warning" />                        
           
           Fail
           </Badge></td>
         </tr>  
       </tbody>
        )
     })     
    }else if(this.state.currentquotationpagenumber ==4){
      return this.state.ontimekpilistfortable.slice(150,200).map((data, index) => {
        const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
        var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shipper}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdate}</td>
           <td>{assigndate}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className="bg-warning" />                        
           
           Fail
           </Badge></td>
         </tr>  
       </tbody>
        )
     })     
    }else if(this.state.currentquotationpagenumber ==5){
      return this.state.ontimekpilistfortable.slice(250,300).map((data, index) => {
        const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
        var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shipper}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdate}</td>
           <td>{assigndate}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className="bg-warning" />                        
           
           Fail
           </Badge></td>
         </tr>  
       </tbody>
        )
     })     
    }else if(this.state.currentquotationpagenumber >= 6){
      return this.state.ontimekpilistfortable.slice(300,1000).map((data, index) => {
        const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
        var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
        return (
         <tbody>
         <tr>
           <th scope="row">{consignmentno}</th>
           <td>{shipper}</td>
           <td>{bookingno}</td>
           <td>{containerno}</td>
           <td>{requestdate}</td>
           <td>{assigndate}</td>
           <td> <Badge color="" className="badge-dot mr-4">
           
           <i className="bg-warning" />                        
           
           Fail
           </Badge></td>
         </tr>  
       </tbody>
        )
     })     
    }
   
  }
  renderalljobschedulelist() {
      

   if(this.state.tabsimportexportalljob == 1){
    return this.state.alljobschedulelistimport.map((data, index) => {
      const {requestdatetime,containerno,consignmentno,no,shippername,bookingno,estimatetime,status,statuscolor,} = data //destructuring
      var rotlength = Object.keys(this.state.alljobschedulelistimport).length;
      return (
       <tbody>
       <tr>
         <th scope="row">{consignmentno}</th>
         <td>{shippername}</td>
         <td>{bookingno}</td>
         <td>{containerno}</td>
         <td>{requestdatetime}</td>
         <td>{estimatetime}</td>
         <td> <Badge color="" className="badge-dot mr-4">
         
         <i className={statuscolor} />                        
         
         {status}
         </Badge></td>
       </tr>       
     </tbody>
      
  
      )
   })
   }else{
    return this.state.alljobschedulelistexport.map((data, index) => {
      const {requestdatetime,containerno,consignmentno,no,shippername,bookingno,estimatetime,status,statuscolor,} = data //destructuring
      var rotlength = Object.keys(this.state.alljobschedulelistexport).length;
      return (
       <tbody>
       <tr>
         <th scope="row">{consignmentno}</th>
         <td>{shippername}</td>
         <td>{bookingno}</td>
         <td>{containerno}</td>
         <td>{requestdatetime}</td>
         <td>{estimatetime}</td>
         <td> <Badge color="" className="badge-dot mr-4">
         
         <i className={statuscolor} />                        
         
         {status}
         </Badge></td>
       </tr>       
     </tbody>
      
  
      )
   })
   }
  }

  gettodaypendingandalljobscheduleandschedule = () =>{
    //before request date count as pending  
    var todaytimestamp = new Date().getTime()/1000
    var status="Order Created"
    var statuscolor = "bg-warning"
    var estimatetimetoshowword = "-"


    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty",this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-gettodaypendingbilltoparty'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      var timestampnow  = new Date().getTime()/1000
      var timestampnowtouse  = new Date().getTime()
      var datestart = new Date(timestampnowtouse).getDate()
      var twodigitdate = ("0" + datestart).slice(-2);
      var monthstart = new Date(timestampnowtouse).getMonth() + 1
      var twodigitmonth = ("0" + monthstart).slice(-2);
      var yearstart = new Date(timestampnowtouse).getFullYear()
      var totaldateend = yearstart + "-" + twodigitmonth + "-" + twodigitdate + "T23:59:59+00:00"
      var totaldatestart = yearstart + "-" + twodigitmonth + "-" + twodigitdate + "T00:00:00+00:00"
      var timestamptodayend = new Date(totaldateend).getTime() / 1000
      var timestamptodaystart = new Date(totaldatestart).getTime() / 1000      

      var timestamptodayendyesterday = timestamptodayend - 86400
      var timestamptodaystartyesterday = timestamptodaystart - 86400
    
      console.log(json.success)      
      //console.log(json)
      if(json.success == 1){

        //today pending ---------------------------------------------------------------
        this.setState({todaypending:0})   
        this.setState({todaydeliver:0}) 
        this.setState({totalinpipeline:0})
        this.setState({todaypendingyesterday:0})   
        this.setState({todaydeliveryesterday:0}) 
        this.setState({totalinpipelineyesterday:0}) 
        var lengthemployee = Object.keys(json.user).length;
        for(let i=0;i<lengthemployee;i++){

          var timestamptruckin = new Date(json.user[i].Activitydatetruckin-28800).getTime()/1000
          var timestamptruckintouse  = new Date(json.user[i].Activitydatetruckin-28800).getTime()
          var datestarttruckin = new Date(timestamptruckintouse).getDate()
          var twodigitdatetruckin = ("0" + datestarttruckin).slice(-2);
          var monthstarttruckin = new Date(timestamptruckintouse).getMonth() + 1
          var twodigitmonthtruckin = ("0" + monthstarttruckin).slice(-2);
          var yearstarttruckin = new Date(timestamptruckintouse).getFullYear()
          var totaldateendtruckin = yearstarttruckin + "-" + twodigitmonthtruckin + "-" + twodigitdatetruckin + "T23:59:59+00:00"
          var totaldatestarttruckin = yearstarttruckin + "-" + twodigitmonthtruckin + "-" + twodigitdatetruckin + "T00:00:00+00:00"
          var timestamptodayendtruckin = new Date(totaldateendtruckin).getTime() / 1000
          var timestamptodaystarttruckin = new Date(totaldatestarttruckin).getTime() / 1000
          //today
          if(json.user[i].Requestdate-28800 == 946684800-28800){
           // console.log("requestdate: " + Moment.unix(json.user[i].Requestdate).format("DD/MM/YYYY HH:mm"))
           // console.log("consignmentno: " + json.user[i].Consignmentno)
          }else if(json.user[i].Requestdate-28800 < timestamptodayend ){
            if(json.user[i].Activitydatetruckin-28800 ==946684800-28800 ){
              //console.log("requestdate: " + Moment.unix(json.user[i].Requestdate).format("DD/MM/YYYY HH:mm"))
             // console.log("Activitydatetruckin: " + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY HH:mm"))
              //console.log("consignmentno: " + json.user[i].Consignmentno)
              this.setState({todaypending:this.state.todaypending + 1})
            }
           
          }
         // console.log("timestamptodayend: " + timestamptodayend)
          //console.log("timestamptodaystart: " + timestamptodaystart)

          if(json.user[i].Activitydatetruckin-28800 <= timestamptodayend && json.user[i].Activitydatetruckin-28800 >= timestamptodaystart ){
           // console.log("activitytruckin: " + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY HH:mm"))
           // console.log("consignmentno: " + json.user[i].Consignmentno)
            this.setState({todaydeliver:this.state.todaydeliver + 1})
          }

          //yesterday
          if(json.user[i].Requestdate-28800 == 946684800-28800){
            // console.log("requestdate: " + Moment.unix(json.user[i].Requestdate).format("DD/MM/YYYY HH:mm"))
            // console.log("consignmentno: " + json.user[i].Consignmentno)
           }else if(json.user[i].Requestdate-28800 < timestamptodayendyesterday ){
             if(json.user[i].Activitydatetruckin-28800 ==946684800-28800 || (json.user[i].Activitydatetruckin-28800 <= timestamptodayendyesterday && json.user[i].Activitydatetruckin-28800 >= timestamptodaystartyesterday)){
              // console.log("requestdate: " + Moment.unix(json.user[i].Requestdate).format("DD/MM/YYYY HH:mm"))
              // console.log("Activitydatetruckin: " + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY HH:mm"))
              // console.log("consignmentno: " + json.user[i].Consignmentno)
               this.setState({todaypendingyesterday:this.state.todaypendingyesterday + 1})
             }
            
           }
          // console.log("timestamptodayendyesterday: " + timestamptodayendyesterday)
         // console.log("timestamptodaystartyesterday: " + timestamptodaystartyesterday)
           if(json.user[i].Activitydatetruckin-28800 <= timestamptodayendyesterday && json.user[i].Activitydatetruckin-28800 >= timestamptodaystartyesterday ){
             // console.log("activitytruckin: " + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY HH:mm"))
             // console.log("consignmentno: " + json.user[i].Consignmentno)
             this.setState({todaydeliveryesterday:this.state.todaydeliveryesterday + 1})
           }


          this.setState({totalinpipeline:this.state.totalinpipeline + 1})      



          //personal schedule
          if(json.user[i].Createdby == this.state.ref){
            if(json.user[i].Requestdate-28800 != 946684800-28800 ){
              if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY') ){
                //console.log("EstimateTimeRange: " + json.user[i].EstimateTimeRange)
                console.log("Requestdate: " + Moment((json.user[i].Requestdate-28800)*1000).format('DD/MM/YYYY'))
                //console.log("todaytimestamp: " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
                //if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY')){
                  //console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'))
                  //console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
                  if(json.user[i].transferhistorydate-28800 != 946684800-28800){
                    status ='Completed'
                    statuscolor='bg-success'
                  }
                  else if(json.user[i].transferbilldate-28800 != 946684800-28800){
                    status ='Billing'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatetruckout-28800 != 946684800-28800){
                    status ='Trucked Out'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatestagingout-28800 != 946684800-28800){
                    status ='Staging Out'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
                    status ='Trucked In'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatestaging-28800 != 946684800-28800){
                    status ='Staging In'
                    statuscolor='bg-yellow'
                  }else{
                    status ='Order Created'
                    statuscolor='bg-warning'
                  }
  
                  if(json.user[i].EstimateTimeRange == 1){
                    estimatetimetoshowword = "8am - 12pm"
                  }else if(json.user[i].EstimateTimeRange == 2){
                    estimatetimetoshowword = "12pm - 4pm"
                  }else if(json.user[i].EstimateTimeRange == 3){
                    estimatetimetoshowword = "4pm - 9pm"
                  }else if(json.user[i].EstimateTimeRange == 0){
                    estimatetimetoshowword = "-"
                  }
                  
                  if(json.user[i].importexport=="Import"){
                    this.setState({personalschedulelistimport:[...this.state.personalschedulelistimport,
                      { no:i,
                        rotno:json.user[i].Rotnumber,
                        consignmentno:json.user[i].Consignmentno,
                        shippername:json.user[i].Shipper.slice(0,28),
                        bookingno:json.user[i].Bookingno,
                        containerno:json.user[i].Containerno,
                        activitystagingindate:json.user[i].Activitydatestaging-28800,
                        activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                        activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                        activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                        activitytransferbilldate:json.user[i].transferbilldate-28800,
                        activitytransferhistory:json.user[i].transferhistorydate-28800,
                        activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                        requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                        estimatetime:estimatetimetoshowword,
                        status:status,
                        statuscolor:statuscolor,
                      },            
                    ]})                    
                  }else{
                    this.setState({personalschedulelistexport:[...this.state.personalschedulelistexport,
                      { no:i,
                        rotno:json.user[i].Rotnumber,
                        consignmentno:json.user[i].Consignmentno,
                        shippername:json.user[i].Shipper.slice(0,28),
                        bookingno:json.user[i].Bookingno,
                        containerno:json.user[i].Containerno,
                        activitystagingindate:json.user[i].Activitydatestaging-28800,
                        activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                        activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                        activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                        activitytransferbilldate:json.user[i].transferbilldate-28800,
                        activitytransferhistory:json.user[i].transferhistorydate-28800,
                        activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                        requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                        estimatetime:estimatetimetoshowword,
                        status:status,
                        statuscolor:statuscolor,
                      },            
                    ]})
                    
                  }
                 
               // }
              
              }else if(json.user[i].Requestdate-28800 < todaytimestamp){
                //console.log("EstimateTimeRange: " + json.user[i].EstimateTimeRange)
                if((json.user[i].Activitydatetruckin-28800 == 946684800-28800 && json.user[i].Activitydatestaging-28800 ==946684800-28800)||(json.user[i].Activitydatetruckin-28800 == 946684800-28800 && json.user[i].Activitydatestaging-28800 !=946684800-28800)){
                  console.log("Requestdate: " + Moment((json.user[i].Requestdate-28800)*1000).format('DD/MM/YYYY'))
                  //console.log("todaytimestamp: " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
                  //if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY')){
                  //console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'))
                  //console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
                  if(json.user[i].transferhistorydate-28800 != 946684800-28800){
                    status ='Completed'
                    statuscolor='bg-success'
                  }
                  else if(json.user[i].transferbilldate-28800 != 946684800-28800){
                    status ='Billing'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatetruckout-28800 != 946684800-28800){
                    status ='Trucked Out'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatestagingout-28800 != 946684800-28800){
                    status ='Staging Out'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
                    status ='Trucked In'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatestaging-28800 != 946684800-28800){
                    status ='Staging In'
                    statuscolor='bg-yellow'
                  }else{
                    status ='Order Created'
                    statuscolor='bg-warning'
                  }
  
                  if(json.user[i].EstimateTimeRange == 1){
                    estimatetimetoshowword = "8am - 12pm"
                  }else if(json.user[i].EstimateTimeRange == 2){
                    estimatetimetoshowword = "12pm - 4pm"
                  }else if(json.user[i].EstimateTimeRange == 3){
                    estimatetimetoshowword = "4pm - 9pm"
                  }else if(json.user[i].EstimateTimeRange == 0){
                    estimatetimetoshowword = "-"
                  }
                  if(json.user[i].importexport=="Import"){
                    this.setState({personalschedulelistimport:[...this.state.personalschedulelistimport,
                      { no:i,
                        rotno:json.user[i].Rotnumber,
                        consignmentno:json.user[i].Consignmentno,
                        shippername:json.user[i].Shipper.slice(0,28),
                        bookingno:json.user[i].Bookingno,
                        containerno:json.user[i].Containerno,
                        activitystagingindate:json.user[i].Activitydatestaging-28800,
                        activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                        activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                        activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                        activitytransferbilldate:json.user[i].transferbilldate-28800,
                        activitytransferhistory:json.user[i].transferhistorydate-28800,
                        activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                        requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                        estimatetime:estimatetimetoshowword,
                        status:status,
                        statuscolor:statuscolor,
                      },            
                    ]})
                  }else{
                    this.setState({personalschedulelistexport:[...this.state.personalschedulelistexport,
                      { no:i,
                        rotno:json.user[i].Rotnumber,
                        consignmentno:json.user[i].Consignmentno,
                        shippername:json.user[i].Shipper.slice(0,28),
                        bookingno:json.user[i].Bookingno,
                        containerno:json.user[i].Containerno,
                        activitystagingindate:json.user[i].Activitydatestaging-28800,
                        activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                        activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                        activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                        activitytransferbilldate:json.user[i].transferbilldate-28800,
                        activitytransferhistory:json.user[i].transferhistorydate-28800,
                        activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                        requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                        estimatetime:estimatetimetoshowword,
                        status:status,
                        statuscolor:statuscolor,
                      },            
                    ]})
                  }
             
                }
              }

            }
                  
              
          }


          //alljob schedule
          if(json.user[i].Requestdate-28800 != 946684800-28800 ){
          if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY') ){
            //console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000).format('DD/MM/YYYY'))
          // console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
           // if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY')){
            // console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000).format('DD/MM/YYYY'))
            // console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
              if(json.user[i].transferhistorydate-28800 != 946684800-28800){
                status ='Completed'
                statuscolor='bg-success'
              }
              else if(json.user[i].transferbilldate-28800 != 946684800-28800){
                status ='Billing'
                statuscolor='bg-success'
              }else if(json.user[i].Activitydatetruckout-28800 != 946684800-28800){
                status ='Trucked Out'
                statuscolor='bg-success'
              }else if(json.user[i].Activitydatestagingout-28800 != 946684800-28800){
                status ='Staging Out'
                statuscolor='bg-success'
              }else if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
                status ='Trucked In'
                statuscolor='bg-success'
              }else if(json.user[i].Activitydatestaging-28800 != 946684800-28800){
                status ='Staging In'
                statuscolor='bg-yellow'
              }else{
                status ='Order Created'
                statuscolor='bg-warning'
              }

              if(json.user[i].EstimateTimeRange == 1){
                estimatetimetoshowword = "8am - 12pm"
              }else if(json.user[i].EstimateTimeRange == 2){
                estimatetimetoshowword = "12pm - 4pm"
              }else if(json.user[i].EstimateTimeRange == 3){
                estimatetimetoshowword = "4pm - 9pm"
              }else if(json.user[i].EstimateTimeRange == 0){
                estimatetimetoshowword = "-"
              }
              
              if(json.user[i].importexport == "Import"){
                this.setState({alljobschedulelistimport:[...this.state.alljobschedulelistimport,
                  { no:i,
                    rotno:json.user[i].Rotnumber,
                    consignmentno:json.user[i].Consignmentno,
                    shippername:json.user[i].Shipper.slice(0,28),
                    bookingno:json.user[i].Bookingno,
                    containerno:json.user[i].Containerno,
                    activitystagingindate:json.user[i].Activitydatestaging-28800,
                    activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                    activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                    activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                    activitytransferbilldate:json.user[i].transferbilldate-28800,
                    activitytransferhistory:json.user[i].transferhistorydate-28800,
                    activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                    requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                    estimatetime:estimatetimetoshowword,
                    status:status,
                    statuscolor:statuscolor,
                  },            
                ]})
              }else{
                this.setState({alljobschedulelistexport:[...this.state.alljobschedulelistexport,
                  { no:i,
                    rotno:json.user[i].Rotnumber,
                    consignmentno:json.user[i].Consignmentno,
                    shippername:json.user[i].Shipper.slice(0,28),
                    bookingno:json.user[i].Bookingno,
                    containerno:json.user[i].Containerno,
                    activitystagingindate:json.user[i].Activitydatestaging-28800,
                    activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                    activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                    activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                    activitytransferbilldate:json.user[i].transferbilldate-28800,
                    activitytransferhistory:json.user[i].transferhistorydate-28800,
                    activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                    requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                    estimatetime:estimatetimetoshowword,
                    status:status,
                    statuscolor:statuscolor,
                  },            
                ]})
              }
             
              //console.log("personalschedulelist: " + this.state.personalschedulelist)
            //}
          
         }else if(json.user[i].Requestdate-28800 < todaytimestamp){
          if((json.user[i].Activitydatetruckin-28800 == 946684800-28800 && json.user[i].Activitydatestaging-28800 ==946684800-28800)||(json.user[i].Activitydatetruckin-28800 == 946684800-28800 && json.user[i].Activitydatestaging-28800 !=946684800-28800)){
            if(json.user[i].transferhistorydate-28800 != 946684800-28800){
              status ='Completed'
              statuscolor='bg-success'
            }
            else if(json.user[i].transferbilldate-28800 != 946684800-28800){
              status ='Billing'
              statuscolor='bg-success'
            }else if(json.user[i].Activitydatetruckout-28800 != 946684800-28800){
              status ='Trucked Out'
              statuscolor='bg-success'
            }else if(json.user[i].Activitydatestagingout-28800 != 946684800-28800){
              status ='Staging Out'
              statuscolor='bg-success'
            }else if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
              status ='Trucked In'
              statuscolor='bg-success'
            }else if(json.user[i].Activitydatestaging-28800 != 946684800-28800){
              status ='Staging In'
              statuscolor='bg-yellow'
            }else{
              status ='Order Created'
              statuscolor='bg-warning'
            }

            if(json.user[i].EstimateTimeRange == 1){
              estimatetimetoshowword = "8am - 12pm"
            }else if(json.user[i].EstimateTimeRange == 2){
              estimatetimetoshowword = "12pm - 4pm"
            }else if(json.user[i].EstimateTimeRange == 3){
              estimatetimetoshowword = "4pm - 9pm"
            }else if(json.user[i].EstimateTimeRange == 0){
              estimatetimetoshowword = "-"
            }
            
            if(json.user[i].importexport =="Import"){
              this.setState({alljobschedulelistimport:[...this.state.alljobschedulelistimport,
                { no:i,
                  rotno:json.user[i].Rotnumber,
                  consignmentno:json.user[i].Consignmentno,
                  shippername:json.user[i].Shipper.slice(0,28),
                  bookingno:json.user[i].Bookingno,
                  containerno:json.user[i].Containerno,
                  activitystagingindate:json.user[i].Activitydatestaging-28800,
                  activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                  activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                  activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                  activitytransferbilldate:json.user[i].transferbilldate-28800,
                  activitytransferhistory:json.user[i].transferhistorydate-28800,
                  activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                  requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                  estimatetime:estimatetimetoshowword,
                  status:status,
                  statuscolor:statuscolor,
                },            
              ]})
            }else{
              this.setState({alljobschedulelistexport:[...this.state.alljobschedulelistexport,
                { no:i,
                  rotno:json.user[i].Rotnumber,
                  consignmentno:json.user[i].Consignmentno,
                  shippername:json.user[i].Shipper.slice(0,28),
                  bookingno:json.user[i].Bookingno,
                  containerno:json.user[i].Containerno,
                  activitystagingindate:json.user[i].Activitydatestaging-28800,
                  activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                  activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                  activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                  activitytransferbilldate:json.user[i].transferbilldate-28800,
                  activitytransferhistory:json.user[i].transferhistorydate-28800,
                  activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                  requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                  estimatetime:estimatetimetoshowword,
                  status:status,
                  statuscolor:statuscolor,
                },            
              ]})
            }
          
          }
         }       
        
        }
        this.setState({loadingdashboard:false})

          //
        }

        this.state.personalschedulelistimport.sort((a,b) => (a.requestdatetime > b.requestdatetime) ?1:-1)
        this.state.personalschedulelistexport.sort((a,b) => (a.requestdatetime > b.requestdatetime) ?1:-1)
        this.state.alljobschedulelistimport.sort((a,b) => (a.requestdatetime > b.requestdatetime) ?1:-1)
        this.state.alljobschedulelistexport.sort((a,b) => (a.requestdatetime > b.requestdatetime) ?1:-1)
      }
      else{
          //this.setState({loading:false}) 
        console.log(json)
        this.setState({loadingdashboard:false})
      }
      //console.log("todaydeliver: " + this.state.todaydeliver)
      //console.log("todaydeliveryesterday: " + this.state.todaydeliveryesterday)

      //console.log("todaypending: " + this.state.todaypending)
      //console.log("todaypendingyesterday: " + this.state.todaypendingyesterday)
      if(this.state.todaydeliver == 0){
        this.setState({percentdeliver:0})
      }else{
        this.setState({percentdeliver:(this.state.todaydeliver - this.state.todaydeliveryesterday)/(this.state.todaydeliver) *100})
      }
      
      if(this.state.todaypending == 0){
        this.setState({percentpending:0})
      }else{
        this.setState({percentpending:(this.state.todaypending - this.state.todaypendingyesterday)/(this.state.todaypending)*100})
      }
      
     
 
      //personal schedule ---------------------------------------------------------------
      // if(json.success == 1){
      //   for(let i=0;i<lengthemployee;i++){
         
      //   }
      // }

      //alljob list schedule
      // if(json.success == 1){
      //   for(let i=0;i<lengthemployee;i++){
      //   //  if(json.user[i].Createdby == this.state.ref){
      //     //console.log("json.user[i].EstimateTimeRange: " + json.user[i].EstimateTimeRange)
      //       if(json.user[i].EstimateTimeRange != 0){
      //         //console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000).format('DD/MM/YYYY'))
      //       // console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
      //         if(Moment(json.user[i].Requestdate*1000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY')){
      //         // console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000).format('DD/MM/YYYY'))
      //         // console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
      //           if(json.user[i].transferhistorydate != 946684800){
      //             status ='Completed'
      //             statuscolor='bg-success'
      //           }
      //           else if(json.user[i].transferbilldate != 946684800){
      //             status ='Billing'
      //             statuscolor='bg-success'
      //           }else if(json.user[i].Activitydatetruckout != 946684800){
      //             status ='Trucked Out'
      //             statuscolor='bg-success'
      //           }else if(json.user[i].Activitydatestagingout != 946684800){
      //             status ='Staging Out'
      //             statuscolor='bg-success'
      //           }else if(json.user[i].Activitydatetruckin != 946684800){
      //             status ='Trucked In'
      //             statuscolor='bg-success'
      //           }else if(json.user[i].Activitydatestaging != 946684800){
      //             status ='Staging In'
      //             statuscolor='bg-yellow'
      //           }else{
      //             status ='Order Created'
      //             statuscolor='bg-warning'
      //           }

      //           if(json.user[i].EstimateTimeRange == 1){
      //             estimatetimetoshowword = "8am - 12pm"
      //           }else if(json.user[i].EstimateTimeRange == 2){
      //             estimatetimetoshowword = "12pm - 3pm"
      //           }else if(json.user[i].EstimateTimeRange == 3){
      //             estimatetimetoshowword = "3pm - 9pm"
      //           }
                
      //           this.setState({alljobschedulelist:[...this.state.alljobschedulelist,
      //             { no:i,
      //               rotno:json.user[i].Rotnumber,
      //               consignmentno:json.user[i].Consignmentno,
      //               shippername:json.user[i].Shipper.slice(0,32),
      //               bookingno:json.user[i].Bookingno,
      //               containerno:json.user[i].Containerno,
      //               activitystagingindate:json.user[i].Activitydatestaging,
      //               activtiytruckindate:json.user[i].Activitydatetruckin,
      //               activitystagingoutdate:json.user[i].Activitydatestagingout,
      //               activitytruckoutdate:json.user[i].Activitydatetruckout,
      //               activitytransferbilldate:json.user[i].transferbilldate,
      //               activitytransferhistory:json.user[i].transferhistorydate,
      //               activitydatecreatenewtime:json.user[i].Activitydatecreatenew,
      //               estimatetime:estimatetimetoshowword,
      //               status:status,
      //               statuscolor:statuscolor,
      //             },            
      //           ]})
      //           //console.log("personalschedulelist: " + this.state.personalschedulelist)
      //         }
            
      //     // }         
          
      //     }
        
        
      //   }
      // }


  })
  }

  buttonclicktoswitchtab = (e,tabid) =>{
    e.preventDefault();
    this.setState({tabs:tabid})
    this.setState({tabsimportexportschedule:1})
    this.setState({tabsimportexportalljob:1})
  } 



  buttonimportexportscheduleclick = (e,importorexportclick) =>{
    //console.log("importexportclick: " + importorexportclick)
    if( importorexportclick == "Import"){
      this.setState({tabsimportexportschedule:1})
    }else{
      this.setState({tabsimportexportschedule:2})
    }    
   // console.log("tabsimportexportschedule: " + this.state.tabsimportexportschedule)
  }



  buttonimportexportalljobclick = (e,importorexportclick) =>{
    //console.log("importexportclick: " + importorexportclick)
    if( importorexportclick == "Import"){
      this.setState({tabsimportexportalljob:1})
    }else{
      this.setState({tabsimportexportalljob:2})
    }    
   // console.log("tabsimportexportschedule: " + this.state.tabsimportexportschedule)
  }


  render() {
    //const {id} = this.props.location.state
  
    return (
      <>
       <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mewahtrans Logistic Sdn Bhd</title>
            </Helmet>
        </div>
        <Header  />
        {/* Card stats */}
        <Container className="mt--8" fluid>
        {this.state.loadingdashboard == true?
         <Row className="mt-9">
         <Col md="12" >
       <img 
       className="img-center"
       width="120"
       height="120"
       alt="..."
       src={require("./../assets/gif/lorrygif3.gif")}
     />
     </Col>
       </Row>
        :
        <div>
         

         {/* Top 3 banner */}
        <Row>
                <Col lg="8" xl="4">
                  <Card className="card-stats mb-6 mb-xl-0 ">
               
                    <CardTitle
                            tag="h2"
                            className="text-uppercase text-muted mb-0 "
                          >
                              <div className="icon icon-shape bg-danger text-white rounded-circle shadow mr-2">
                            <i className="ni ni-delivery-fast" />
                            
                          </div>
                                 Delivered Today:  <span className="h1 font-weight-bold mb-0">
                          {this.state.todaydeliver}
                          </span>
                          </CardTitle>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        {this.state.percentdeliver < 0 ?
                              <span className="text-danger mr-2">
                              <i className="fa fa-arrow-down" /> {Math.round(this.state.percentdeliver * 100) / 100}%
                            </span>
                        :
                        <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> {Math.round(this.state.percentdeliver * 100) / 100}%
                      </span>
                        }
                  
                        <span className="text-nowrap">Since yesterday</span>
                      </p> */}
                   
                  </Card>
                </Col>
                <Col lg="8" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                <CardTitle
                            tag="h2"
                            className="text-uppercase text-muted mb-0 "
                          >
                             <div className="icon icon-shape bg-warning text-white rounded-circle shadow mr-2">
                            <i className="ni ni-time-alarm" />
                          </div>
                              Pending Today:  <span className="h1 font-weight-bold mb-0">
                          {this.state.todaypending}
                          </span>
                          </CardTitle>
                          </Card>
                </Col>
                <Col lg="8" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                  <CardTitle
                            tag="h2"
                            className="text-uppercase text-muted mb-0 "
                          >
                             <div className="icon icon-shape bg-yellow text-white rounded-circle shadow mr-2">
                            <i className="ni ni-chart-bar-32" />
                          </div>
                          Total In Pipeline:  <span className="h1 font-weight-bold mb-0">
                          {this.state.totalinpipeline}
                          </span>
                          </CardTitle>
                  </Card>
                </Col>
             
         </Row>
      
        {/* New Version All Job List */}
         {/* Page content */} 
      
         <Row>
         {/* Personal Table */}
         <Col className="mb-5 mb-xl-0" xl="12">
         

           <Card className="shadow mt-4">
             {/* Header */}
         <Row className="align-items-center pl-2 pr-2">
                

                   <h3 className="mb-0 mt-2 ml-3 mr-2">Order List:</h3>
                     {/* Import Export */}
                     {window.innerWidth < 760 ?
<Nav
className="nav-fill  flex-md-row  "
id="tabs-icons-text"
pills
role="tablist"
>
<NavItem>
<NavLink
 aria-selected={this.state.tabsimportexportschedule === 1}
 className={classnames("mb-sm-3 mb-md-0", {
   active: this.state.tabsimportexportschedule === 1
 })}
 onClick={e => (this.buttonimportexportscheduleclick(e,"Import"))}
 href="#pablo"
 role="tab"
>
 Import
</NavLink>
</NavItem>
<NavItem>
<NavLink
 aria-selected={this.state.tabsimportexportschedule === 2}
 className={classnames("mb-sm-3 mb-md-0", {
   active: this.state.tabsimportexportschedule === 2
 })}
 onClick={e =>(this.buttonimportexportscheduleclick(e,"Export"))}
 href="#pablo"
 role="tab"
>
 Export
</NavLink>
</NavItem>

</Nav>
:
<Nav
 className="nav-fill flex-column flex-md-row  "
 id="tabs-icons-text"
 pills
 role="tablist"
>
 <NavItem>
   <NavLink
     aria-selected={this.state.tabsimportexportschedule === 1}
     className={classnames("mb-sm-3 mb-md-0", {
       active: this.state.tabsimportexportschedule === 1
     })}
     onClick={e =>(this.buttonimportexportscheduleclick(e,"Import"))}
     href="#pablo"
     role="tab"
   >
     Import
   </NavLink>
 </NavItem>
 <NavItem>
   <NavLink
     aria-selected={this.state.tabsimportexportschedule === 2}
     className={classnames("mb-sm-3 mb-md-0", {
       active: this.state.tabsimportexportschedule === 2
     })}
     onClick={e =>(this.buttonimportexportscheduleclick(e,"Export"))}
     href="#pablo"
     role="tab"
   >
     Export
   </NavLink>
 </NavItem>
 
</Nav>}      
            

             


                 {window.innerWidth <= 760 ?
                <InputGroup className="col-11 mt-2 form-inline alignmiddletbutton d-md-flex ml-lg-auto mb--3 input-group-alternative ">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                   
                      <i className="fas fa-search" />
                     
                    </InputGroupText>
                  </InputGroupAddon>
                    <Input
                      placeholder="Search" 
                      value={this.state.searchtextvalue}
                      onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                      type="text"
                    />
                  
                 {this.state.searchtextvalue == "" ?
                 null:
                 <InputGroupAddon addonType="prepend">
                 <InputGroupText>
                 <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                  
                <span className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</span >
                   
                 </InputGroupText>
               </InputGroupAddon>
                 }                  
              
                </InputGroup>
              :
<InputGroup className="col-4 mt-2 form-inline mr-3 d-none d-md-flex ml-lg-auto mb--3 input-group-alternative bg-white mt-4 mb-2">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                   
                      <i className="fas fa-search" />
                     
                    </InputGroupText>
                  </InputGroupAddon>
                    <Input
                      placeholder="Search" 
                      value={this.state.searchtextvalue}
                      onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                      type="text"
                    />
                  
                 {this.state.searchtextvalue == "" ?
                 null:
                 <InputGroupAddon addonType="prepend">
                 <InputGroupText>
                 <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                  
                <span className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</span >
                   
                 </InputGroupText>
               </InputGroupAddon>
                 }
                    
                     
                  
                </InputGroup>
                }
          </Row>

             <CardHeader className="border-0">
                <Row>
                  <Col xl="3">
                     

  {/* Your schedule , all schedule */}
<Row className="mt-2"> 
  
{window.innerWidth < 760 ?
<Nav
className="nav-fill  flex-md-row  "
id="tabs-icons-text"
pills
role="tablist"
>
<NavItem>
<NavLink
 aria-selected={this.state.tabsimportexportschedule === 1}
 className={classnames("mb-sm-3 mb-md-0", {
   active: this.state.tabsimportexportschedule === 1
 })}
 onClick={e => (this.buttonimportexportscheduleclick(e,"Import"))}
 href="#pablo"
 role="tab"
>
 Your Schedule
</NavLink>
</NavItem>
<NavItem>
<NavLink
 aria-selected={this.state.tabsimportexportschedule === 2}
 className={classnames("mb-sm-3 mb-md-0", {
   active: this.state.tabsimportexportschedule === 2
 })}
 onClick={e =>(this.buttonimportexportscheduleclick(e,"Export"))}
 href="#pablo"
 role="tab"
>
 All Schedule
</NavLink>
</NavItem>

</Nav>
:
<Nav
 className="nav-fill flex-column flex-md-row  "
 id="tabs-icons-text"
 pills
 role="tablist"
>
 <NavItem>
   <NavLink
     aria-selected={this.state.tabsimportexportschedule === 1}
     className={classnames("mb-sm-3 mb-md-0", {
       active: this.state.tabsimportexportschedule === 1
     })}
     onClick={e =>(this.buttonimportexportscheduleclick(e,"Import"))}
     href="#pablo"
     role="tab"
   >
     Your Schedule
   </NavLink>
 </NavItem>
 <NavItem>
   <NavLink
     aria-selected={this.state.tabsimportexportschedule === 2}
     className={classnames("mb-sm-3 mb-md-0", {
       active: this.state.tabsimportexportschedule === 2
     })}
     onClick={e =>(this.buttonimportexportscheduleclick(e,"Export"))}
     href="#pablo"
     role="tab"
   >
     All Schedule
   </NavLink>
 </NavItem>
 
</Nav>}
</Row>

  {/* today,before, future, all */}
  <Row  className="mt-2">
  
  {window.innerWidth < 760 ?
<Nav
className="nav-fill  flex-md-row  "
id="tabs-icons-text"
pills
role="tablist"
>
<NavItem>
  <NavLink
    aria-selected={this.state.tabsimportexportschedule === 1}
    className={classnames("mb-sm-3 mb-md-0", {
      active: this.state.tabsimportexportschedule === 1
    })}
    onClick={e => (this.buttonimportexportscheduleclick(e,"Import"))}
    href="#pablo"
    role="tab"
  >
    Today/Before
  </NavLink>
</NavItem>
<NavItem>
  <NavLink
    aria-selected={this.state.tabsimportexportschedule === 2}
    className={classnames("mb-sm-3 mb-md-0", {
      active: this.state.tabsimportexportschedule === 2
    })}
    onClick={e =>(this.buttonimportexportscheduleclick(e,"Export"))}
    href="#pablo"
    role="tab"
  >
    Future
  </NavLink>
</NavItem>
<NavItem>
  <NavLink
    aria-selected={this.state.tabsimportexportschedule === 2}
    className={classnames("mb-sm-3 mb-md-0", {
      active: this.state.tabsimportexportschedule === 2
    })}
    onClick={e =>(this.buttonimportexportscheduleclick(e,"Export"))}
    href="#pablo"
    role="tab"
  >
    All
  </NavLink>
</NavItem>

</Nav>
:
<Nav
    className="nav-fill flex-column flex-md-row  "
    id="tabs-icons-text"
    pills
    role="tablist"
  >
    <NavItem>
      <NavLink
        aria-selected={this.state.tabsimportexportschedule === 1}
        className={classnames("mb-sm-3 mb-md-0", {
          active: this.state.tabsimportexportschedule === 1
        })}
        onClick={e =>(this.buttonimportexportscheduleclick(e,"Import"))}
        href="#pablo"
        role="tab"
      >
        Today/Before
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        aria-selected={this.state.tabsimportexportschedule === 2}
        className={classnames("mb-sm-3 mb-md-0", {
          active: this.state.tabsimportexportschedule === 2
        })}
        onClick={e =>(this.buttonimportexportscheduleclick(e,"Export"))}
        href="#pablo"
        role="tab"
      >
        Future
      </NavLink>
    </NavItem>

    {/* <NavItem>
      <NavLink
        aria-selected={this.state.tabsimportexportschedule === 3}
        className={classnames("mb-sm-3 mb-md-0", {
          active: this.state.tabsimportexportschedule === 3
        })}
        onClick={e =>(this.buttonimportexportscheduleclick(e,"Export"))}
        href="#pablo"
        role="tab"
      >
        All
      </NavLink>
    </NavItem> */}
    
  </Nav>}
   </Row>
                  </Col>


                  <Col className="border-left" xl="9">
                    <h3> Status: </h3>
                    <Row>
                      <Col xl="2">
                        <Row className="ml-2">
                      <Checkbox     
                        className="ml-4"             
                        value={this.state.checkboxvalue}
                        onChange = {(event) => this.setState({ checkboxvalue: event.target.value })}
                      ></Checkbox>
                      <h5 style={{marginLeft:5}}>Order created </h5>
                      </Row>
                      <Row className="ml-2">
                      <Checkbox     
                        className="ml-4"             
                        value={this.state.checkboxvalue}
                        onChange = {(event) => this.setState({ checkboxvalue: event.target.value })}
                      ></Checkbox>
                      <h5 style={{marginLeft:5}}>Order accepted </h5>
                      </Row>
                   

                      </Col>

                      <Col xl="2">
                        <Row className="ml-2">
                      <Checkbox     
                        className="ml-4"             
                        value={this.state.checkboxvalue}
                        onChange = {(event) => this.setState({ checkboxvalue: event.target.value })}
                      ></Checkbox>
                      <h5 style={{marginLeft:5}}>Driver accept </h5>
                      </Row>
                      <Row className="ml-2">
                      <Checkbox     
                        className="ml-4"             
                        value={this.state.checkboxvalue}
                        onChange = {(event) => this.setState({ checkboxvalue: event.target.value })}
                      ></Checkbox>
                      <h5 style={{marginLeft:5}}>To Depo </h5>
                      </Row>
                    
                     

                      </Col>

                      <Col xl="2">
                        <Row className="ml-2">
                      <Checkbox     
                        className="ml-4"             
                        value={this.state.checkboxvalue}
                        onChange = {(event) => this.setState({ checkboxvalue: event.target.value })}
                      ></Checkbox>
                      <h5 style={{marginLeft:5}}>Trucked in </h5>
                      </Row>
                      <Row className="ml-2">
                      <Checkbox     
                        className="ml-4"             
                        value={this.state.checkboxvalue}
                        onChange = {(event) => this.setState({ checkboxvalue: event.target.value })}
                      ></Checkbox>
                      <h5 style={{marginLeft:5}}>Trucked out </h5>
                      </Row>
                    
                      </Col>

                      <Col xl="2">
                        <Row className="ml-2">
                      <Checkbox     
                        className="ml-4"             
                        value={this.state.checkboxvalue}
                        onChange = {(event) => this.setState({ checkboxvalue: event.target.value })}
                      ></Checkbox>
                      <h5 style={{marginLeft:5}}>Staging </h5>
                      </Row>
                      <Row className="ml-2">
                      <Checkbox     
                        className="ml-4"             
                        value={this.state.checkboxvalue}
                        onChange = {(event) => this.setState({ checkboxvalue: event.target.value })}
                      ></Checkbox>
                      <h5 style={{marginLeft:5}}>Lift on  </h5>
                      </Row>
                      

                      </Col>

                      <Col xl="2">
                      <Row className="ml-2">
                      <Checkbox     
                        className="ml-4"             
                        value={this.state.checkboxvalue}
                        onChange = {(event) => this.setState({ checkboxvalue: event.target.value })}
                      ></Checkbox>
                      <h5 style={{marginLeft:5}}>Driver assigned </h5>
                      </Row>
                     
                      <Row className="ml-2">
                      <Checkbox     
                        className="ml-4"             
                        value={this.state.checkboxvalue}
                        onChange = {(event) => this.setState({ checkboxvalue: event.target.value })}
                      ></Checkbox>
                      <h5 style={{marginLeft:5}}>Delivering </h5>
                      </Row>
                     
                     

                    
                     
                      </Col>
                    </Row>
               
                   
                  </Col>
                </Row>
               
             </CardHeader>
             

             {/* table  */}
             <Table className="align-items-center table-flush" responsive>
             <thead className="thead-light">
               <tr>
               <th scope="col">No.</th>
                 <th scope="col">Consignment No.</th>
                 <th scope="col">Shipper/Consignee</th>
                 <th scope="col">Booking No.</th>
                 <th scope="col">Container No.</th>
                 <th scope="col">Seal No.</th>
                 <th scope="col">Estimate Time</th>
                 <th scope="col">Live Status</th>
                 <th scope="col">-</th>
               </tr>
             </thead>
               
               
              {/* bodytable */}
             <tbody>
         <tr>
           <th scope="row">1.</th>
           <td>46789-1</td>
           <td>JUBIN BMS</td>
           <td>ASDF123</td>
           <td>YA123123</td>
           <td>GASDF123</td>
           <td>9:00AM - 12:00PM</td>
           <td> <Badge color="bg-warning" className="badge-dot mr-4">           
           <i className={"bg-warning"} />
           Staging
           </Badge></td>
           <td>
      
                            <i className="ni ni-bold-right" />
                       
          </td>  
         </tr>    
         <tr>
           <th scope="row">2.</th>
           <td>46789-2</td>
           <td>JUBIN BMS</td>
           <td>ASDF123</td>
           <td>YA123123</td>
           <td>GASDF123</td>
           <td>9:00AM - 12:00PM</td>
           <td> <Badge color="bg-success" className="badge-dot mr-4">           
           <i className={"bg-success"} />
           Trucked In
           </Badge></td>
           <td>
      
                            <i className="ni ni-bold-right" />
                       
          </td>  
         </tr>    
         <tr>
           <th scope="row">3.</th>
           <td>46789-3</td>
           <td>JUBIN BMS</td>
           <td>ASDF123</td>
           <td>YA123123</td>
           <td>GASDF123</td>
           <td>9:00AM - 12:00PM</td>
           <td> <Badge color="bg-success" className="badge-dot mr-4">           
           <i className={"bg-success"} />
           Trucked In
           </Badge></td>
           <td>
      
      <i className="ni ni-bold-right" />
 
</td>   
         </tr>    
         <tr>
           <th scope="row">4.</th>
           <td>46789-4</td>
           <td>JUBIN BMS</td>
           <td>ASDF1234</td>
           <td>YA123123</td>
           <td>GASDF123</td>
           <td>9:00AM - 12:00PM</td>
           <td> <Badge color="bg-danger" className="badge-dot mr-4">           
           <i className={"bg-danger"} />
           Order created
           </Badge></td>
           <td>
      
                            <i className="ni ni-bold-right" />
                       
          </td> 
         </tr>    
         <tr>
           <th scope="row">5.</th>
           <td>46789-5</td>
           <td>JUBIN BMS</td>
           <td>ASDF1253</td>
           <td>YA123123</td>
           <td>GASDF123</td>
           <td>9:00AM - 12:00PM</td>
           <td> <Badge color="bg-success" className="badge-dot mr-4">           
           <i className={"bg-success"} />
           Trucked In
           </Badge></td>
           <td>
      
                            <i className="ni ni-bold-right" />
                       
          </td>  
         </tr>    
         <tr>
           <th scope="row">6.</th>
           <td>46789-5</td>
           <td>JUBIN BMS</td>
           <td>ASDF1253</td>
           <td>YA123123</td>
           <td>GASDF123</td>
           <td>9:00AM - 12:00PM</td>
           <td> <Badge color="bg-success" className="badge-dot mr-4">           
           <i className={"bg-success"} />
           Trucked In
           </Badge></td>
           <td>
      
                            <i className="ni ni-bold-right" />
                       
          </td>  
         </tr>  
         <tr>
           <th scope="row">7.</th>
           <td>46789-5</td>
           <td>JUBIN BMS</td>
           <td>ASDF1253</td>
           <td>YA123123</td>
           <td>GASDF123</td>
           <td>9:00AM - 12:00PM</td>
           <td> <Badge color="bg-success" className="badge-dot mr-4">           
           <i className={"bg-success"} />
           Trucked In
           </Badge></td>
           <td>
      
                            <i className="ni ni-bold-right" />
                       
          </td>  
         </tr>  
         <tr>
           <th scope="row">8.</th>
           <td>46789-5</td>
           <td>JUBIN BMS</td>
           <td>ASDF1253</td>
           <td>YA123123</td>
           <td>GASDF123</td>
           <td>9:00AM - 12:00PM</td>
           <td> <Badge color="bg-success" className="badge-dot mr-4">           
           <i className={"bg-success"} />
           Trucked In
           </Badge></td>
           <td>
      
                            <i className="ni ni-bold-right" />
                       
          </td>  
         </tr>  
         <tr>
           <th scope="row">9.</th>
           <td>46789-5</td>
           <td>JUBIN BMS</td>
           <td>ASDF1253</td>
           <td>YA123123</td>
           <td>GASDF123</td>
           <td>9:00AM - 12:00PM</td>
           <td> <Badge color="bg-success" className="badge-dot mr-4">           
           <i className={"bg-success"} />
           Trucked In
           </Badge></td>
           <td>
      
                            <i className="ni ni-bold-right" />
                       
          </td>  
         </tr>  
         <tr>
           <th scope="row">10.</th>
           <td>46789-5</td>
           <td>JUBIN BMS</td>
           <td>ASDF1253</td>
           <td>YA123123</td>
           <td>GASDF123</td>
           <td>9:00AM - 12:00PM</td>
           <td> <Badge color="bg-success" className="badge-dot mr-4">           
           <i className={"bg-success"} />
           Trucked In
           </Badge></td>
           <td>
      
                            <i className="ni ni-bold-right" />
                       
          </td>  
         </tr>  
         <tr>
           <th scope="row">11.</th>
           <td>46789-5</td>
           <td>JUBIN BMS</td>
           <td>ASDF1253</td>
           <td>YA123123</td>
           <td>GASDF123</td>
           <td>9:00AM - 12:00PM</td>
           <td> <Badge color="bg-success" className="badge-dot mr-4">           
           <i className={"bg-success"} />
           Trucked In
           </Badge></td>
           <td>
      
                            <i className="ni ni-bold-right" />
                       
          </td>  
         </tr>  

              </tbody>
             </Table>
           </Card>
         </Col>
        
       </Row>
      


          </div>
        }
        
        </Container>
     
     
      {/* on real time kpi pop out */}
      <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.detailpopout}
          toggle={() => this.setState({detailpopout:false})}
        >
          {this.state.loadingmodal?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>
         :
          <div>
          <div className="modal-header">
          <h1 className="modal-title" id="detailpopoutLabel">
          On Time KPI
            </h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailpopout:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
          <Row className="mt-1">
          
         {/* Personal Table */}
       <Col className="mb-5 mb-xl-0" xl="12">
           <Card className="shadow">
           <CardBody >
           {this.renderTablePagination()}
             <Table className="align-items-center table-flush" responsive>
               <thead className="thead-light">
                 <tr>
                   <th scope="col">Consignment No.</th>
                   <th scope="col">Shipper</th>
                   <th scope="col">Booking No.</th>
                   <th scope="col">Container No.</th>
                   <th scope="col">Request Date</th>
                   <th scope="col">Assign Date</th>
                   <th scope="col">Status</th>
                 </tr>
               
               </thead>
               
               {this.renderontimekpimodallist()}
              
             </Table>
             </CardBody>
           </Card>
         </Col>
        
       </Row>
      
       
        
       </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailpopout:false})}
            >
              Close
            </Button>
         
          </div>
          </div>}
          
          </Modal>  

     
          <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.versioncheckopen}
          toggle={() => this.setState({versioncheckopen:false})}
        >
          {this.state.loadingmodal?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>
         :
          <div>
          <div className="modal-header">
        
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailpopout:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
          <Row className="mt-1">
          
         {/* Personal Table */}
       <Col className="mb-5 mb-xl-0" xl="12">
       <h1 className="modal-title" id="detailpopoutLabel">
         You need to update this version , how to update , you check this link below
            </h1>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() =>window.open(this.state.versionvalueurlvideo, "_blank")}
            >
              SHow Video
            </Button>
         </Col>
        
       </Row>
      
       
        
       </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailpopout:false})}
            >
              Close
            </Button>
         
          </div>
          </div>}
          
          </Modal>  
     
      </>
    );
  }
}

export default Dashboard;
